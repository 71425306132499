<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.minimum_wage"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                  <el-form
                        ref="form"
                        status-icon
                        :model="form"
                        :rules="rules"
                        >
                      <el-row :gutter="20">
                        <el-col :span="24">
                          <!-- <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("message.branch") }}
                          </span> -->
                          <el-form-item prop="branch_id" :label="$t('message.branch')">
                            <select-branch
                              :id="form.branch_id"
                              :size="'medium'"
                              v-model="form.branch_id"
                             
                            >
                            </select-branch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item
                            :class="mode ? 'input__day' : 'input__night'"
                              :label="$t('message.wage')"
                              prop="wage"
                              class="label_mini"  >
                              <crm-input
                                  :size="'medium'"
                                  :type="'number'"
                                  :inputValue="form.wage"
                                  v-model="form.wage"
                                  :placeholder="$t('message.wage')"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.from_date") }}
                            </span>
                            <el-form-item prop="from_date">
                              <crm-date-picker
                                v-model="form.from_date"
                                :date="form.from_date"
                                :size="'medium'"
                                :format="'yyyy-MM-dd'"
                                :value-format="'yyyy-MM-dd'"
                                :class="mode ? 'input__day' : 'input__night'"
                              >
                              </crm-date-picker>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.to_date") }}
                            </span>
                            <el-form-item prop="to_date">
                              <crm-date-picker
                                v-model="form.to_date"
                                :date="form.to_date"
                                :size="'medium'"
                                :format="'yyyy-MM-dd'"
                                :value-format="'yyyy-MM-dd'"
                                :class="mode ? 'input__day' : 'input__night'"
                              >
                              </crm-date-picker>
                            </el-form-item>
                          </div>
                        </el-col>
                        <!-- end col -->
                      </el-row>
                     <hr/>
                     <div class="app-form__group mb-4 timeline-items__right rounded-sm w-100 p-4 demo-border">
                      <div
                    class="app-modal__header d-flex f-between p-2 m-title-modal"
                      >
                          <p class="medium--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                            {{ $t("message.tariff_categories") }}
                          </p>
                          <div>
                            <el-button type="success" size="medium" @click="addItem()"  >
                              {{$t("message.add_new")}}
                            </el-button>
                          </div>
                      </div>
                       
                        <div  v-for="(item, index) in form.coefficients" :key="'q_'+index" style="display: flex; gap:20px">
                          <b style="display:flex; align-items:flex-end; margin-bottom:10px">{{index+1}}.</b>
                          <div style="width:50%">
                            <el-form-item class="mb-0" :prop="'coefficients.' + index + '.coefficient'" :label="$t('message.coefficient')" :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.coefficient')}), trigger: 'change' }">
                              <crm-input
                                v-model="item.coefficient"
                                :type="'number'"
                                :size="'small'"
                                :min="0"
                                :class="mode ? 'input__day' : 'input__night'"
                              >
                              </crm-input>
                            </el-form-item>
                          </div>
                          <div style="padding-left: 10px;padding-right: 10px; display: flex;align-items: flex-end;">
                            <div class="app-form__group mb-3">
                               <div>
                                <b>{{$t('message.wage')}}</b>: {{  form.wage&&item.coefficient? (form.wage*item.coefficient).toLocaleString("fi-FI"):'' }}
                              </div>
                              </div>
                          </div>
                          <div  style="padding-left: 10px;padding-right: 10px; display: flex;align-items: flex-end;">
                            <el-button type="danger" :icon="'el-icon-delete'"   @click="removeItem(index)"/>
                          </div>
                        </div>
                      </div>
                  </el-form>
                 
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawer],
    name: "NationController",
    data() {
        return {
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "minimumWage/rules",
            model: "minimumWage/model",
            columns: "minimumWage/columns",
            mode:"MODE",
        }),
    },
    methods: {
        ...mapActions({
            save: "minimumWage/store",
        }),
        addItem(){
          this.form.coefficients.push({
                coefficient: null
              })
        },
        removeItem(index){
          this.form.coefficients.splice(index, 1);
        },
        hendelInput(index, e){
          console.log(e);
          this.form.coefficients;
          console.lo
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
